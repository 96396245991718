$(document).ready(function(){
	

$(document).foundation();

$('#acoustical').click(function(){
	$('.acoustical.nav-level2').addClass('active');
	return false;
});

$('.close-button.subnav').click(function(){
	$('.nav-level2').removeClass('active');
});

$('#mobile-menu').click(function(){
	$('.mobile-nav-wrap').toggle(300);	
});

$('.has-subnav').click(function(){
	$('> ul', this).toggle(300);	
});


$('.type-filter').change(function(){
	
	var type = $('.type-filter:checked').val();
	if (type === "all") {
		$('.product-sub').show();
		
	} else {
		type = "."+type;
		$('.product-sub').hide();
		$(type).show();
	}

});

});